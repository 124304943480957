.root-form {
    padding-left: 40px;
    padding-right: 50px;
    padding-top: 0px;
    padding-bottom: 100px;
    width: 100%;
}

.page-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 26px;
}

.page-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
