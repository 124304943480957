.global-tab-control {
  .MuiTab-root {
      font-family: var(--bs-body-font-family);
      background-color: #f8f9fa;
      color: #000;
      font-size: 16px;
      text-transform: none;
      border: none;
      padding: 10px 20px;
      min-height: 48px;
      transition: none;

      &.Mui-selected {
          background-color: #e0e0e0;
          color: #000;
          // font-weight: bold;
      }

      &:focus {
          outline: none;
      }
  }

  .MuiTabs-indicator {
      background-color: #000;
      height: 2px;
      transition: none;
  }
}
