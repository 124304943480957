.sidebar {
  display: flex;
  flex-direction: column;
  padding: 15px;
  /* background-color: #0079c2; */
  /* background-color: #004f80; */
  transition: width 0.3s;
  position: relative;
}

.sidebar.expanded {
  width: 210px;
}

.sidebar.collapsed {
  width: 60px;
}

.collapse-btn {
  
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  align-self: flex-end; /* Pushes the button to the right */
  width: 30px;
  height: 40px;
}

.search-input {
  margin-bottom: 15px;
  padding: 8px;
  border-radius: 0;
  border: 1px solid #ced4da;
}

.search-input:focus {
  border-color: #ced4da;
  outline: none;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  /* background-color: red; */
}

.menu-item {
  margin-bottom: 10px;
}

.menu-link {
  text-decoration: none;
  color: white;
  font-size: 22px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
  padding: 8px 12px;
  display: flex;
  align-items: center;
}

.link-icon {
  width: 35px;
  height: 35px;
  margin-right: 20px;
  /* color: white; */
}

.sidebar.collapsed .menu-link {
  justify-content: center;
}

.sidebar.collapsed .link-icon {
  margin-right: 0;
}
