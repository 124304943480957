.root-form {
    padding: 20px;
}

.page-title {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.page-icon {
    margin-right: 10px;
    width: 24px;
}

.download-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}



.no-files-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #6c757d;
    font-size: 1.2rem;
    text-align: center;
    border: 1px solid #dee2e6;
    /* border-radius: 8px; */
    background-color: #f8f9fa;
}

.no-files-icon {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
}
