/* Dashboard.css */
.dashboard {
    text-align: center;
    padding: 50px;
    background-color: #f5f5f5;
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.dashboard h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
}

.dashboard p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 40px;
}

.version-history {
    text-align: left;
    display: inline-block;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
}

.version-history h2 {
    color: #444;
    font-size: 1.8em;
    margin-bottom: 10px;
}

.version-history ul {
    list-style-type: none;
    padding: 0;
}

.version-history ul li {
    background-color: #e9ecef;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 1.1em;
    color: #333;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.version-history ul li:last-child {
    margin-bottom: 0;
}
