:root {
    --border-color: #ffffff;
    --header-bg-color: rgb(0, 121, 194);
    --header-text-color: #e6e6e6;
    --header-border-right-color: #ccc;
    --sort-icon-color: #ffffff;
    --even-row-bg-color: rgb(229, 228, 228);
    --odd-row-bg-color: #ffffff;
  }
  
  .ag-theme-alpine {
    height: auto;
    width: 100%;
    border: 1px solid var(--border-color);
    
    .ag-header-cell {
      background-color: var(--header-bg-color) !important;
      color: var(--header-text-color) !important;
      border-right: 1px solid var(--header-border-right-color); // Optional: keep border style between header cells
      font-size: 18px;
      
      .ag-header-cell-label .ag-icon {
        fill: var(--sort-icon-color) !important; // For SVG icons
        color: var(--sort-icon-color) !important; // For font icons
      }
      
      .ag-header-cell-label .ag-icon-asc,
      .ag-header-cell-label .ag-icon-desc {
        fill: var(--sort-icon-color) !important; // For SVG icons
        color: var(--sort-icon-color) !important; // For font icons
      }
      
      .ag-header-cell-menu-button .ag-icon {
        fill: var(--sort-icon-color) !important; // For SVG icons
        color: var(--sort-icon-color) !important; // For font icons
      }
    }
    
    .ag-row:nth-child(even) {
      background-color: var(--even-row-bg-color); // Light grey color for even rows
    }
    
    .ag-row:nth-child(odd) {
      background-color: var(--odd-row-bg-color); // White color for odd rows
    }
    
    .ag-cell {
      font-size: 16px;
    }
  }
  