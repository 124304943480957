// Base textbox style
.global-textbox-base {
  border: 1px solid #ccc; // Flat, sharp borders
  border-radius: 0; // No rounded corners
  padding: 8px;
  font-size: 20px; //16
  box-sizing: border-box;
  outline: none;
  margin-bottom: 40px;
  border-color: rgb(115, 115, 115);

  &:focus {
    border-color: #007bff; // Border color on focus
  }
}

// Regular width textbox
.global-textbox-regular {
  @extend .global-textbox-base;
  width: 600px;
}

// Large width textbox
.global-textbox-large {
  @extend .global-textbox-base;
  width: 800px;
}

.global-textbox-large-large {
  @extend .global-textbox-base;
  width: 1000px;
}

// Short width textbox
.global-textbox-short {
  @extend .global-textbox-base;
  width: 300px;
}