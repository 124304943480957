@import 'theme';

.submit-form-button-base {
  background-color: $color-primary-button;
  border-width: 1px;
  border-style: solid;
  border-color: $color-primary-button-border;
  border-radius: 0;
  color: white;
  // padding: 5px;
  font-size: 24px;
  // margin-right: 20px;

  &:hover {
    background-color: $color-primary-button-hover;
  }
}

.submit-form-button {
  @extend .submit-form-button-base;
  width: 250px;
}

.submit-form-button-large {
  @extend .submit-form-button-base;
  width: 400px;
}

.submit-form-button-no-width {
  @extend .submit-form-button-base;
}



.form-input-file input[type="file"] {
  display: none;
}

.form-button-base {
  background-color: $color-secondary-button;
  border-width: 1px;
  border-style: solid;
  border-color: $color-secondary-button-border;
  border-radius: 0;
  color: #fff;
  padding: 5px;
  font-size: 18px;
  margin-right: 20px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: $color-secondary-button-hover;
  }
}

.form-button {
  @extend .form-button-base;
  width: 250px;
}

.form-button-large {
  @extend .form-button-base;
  width: 400px;
}

.file-name {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}