.user-license-manager {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content horizontally */
  justify-content: flex-start; /* Aligns content to the top */
  height: 100vh;
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 40px; /* Adjust the margin as needed */
}

.user-license-manager h1 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.user-license-manager p {
  font-size: 1.5em;
  color: gray;
}
