@import './global-css/theme';
@import './global-css/DataGrid.scss';
@import './global-css/Buttons.scss';
@import './global-css/Labels.scss';
@import './global-css/RightSidePage.scss';
@import './global-css/Textbox.scss';
@import './global-css/TabControl.scss';


.page-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  // background-color: red;
  fill: red;
}


.v2-download-grid-icon-button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.external-app-themed-color {
  background-color: $color-primary;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 26px;
  /* background-color: green; */
}



.form-textlabel {
  font-size: 18px;
  margin-bottom: 15px;
}

.form-textbox {
  width: 600px !important;
  margin-bottom: 20px;
}

.form-textarea {
  /* width: 800px !important; */
  margin-bottom: 20px;
}

.form-combobox {
  width: 600px !important;
  margin-bottom: 20px;
}

.form-combobox-400 {
  width: 400px !important;
}

/* NewLicenseForm.css */

.tabs {
  border-radius: 0 !important;
  /* Remove rounded corners */
}

.tabContainer {
  margin-bottom: 20px;
}

.nav-tabs .nav-link {
  font-size: 18px;
  /* Set font size */
  border-radius: 0 !important;
  /* Remove rounded corners */

}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-radius: 0 !important;
  /* Remove rounded corners for active tab */
}

.tab-content {
  border: 1px solid #dee2e6;
  /* border-top: none;  */
  padding: 20px;
  border-radius: 0 0 0.25rem 0.25rem;
  /* Remove rounded corners except bottom */
}