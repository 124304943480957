.header {
    color: black;
    display: flex;
    justify-content: space-between;
    padding: 0px 5px;
    align-items: center;
}

.brand {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
}

.logo {
    width: 160px;
    height: auto;
    margin: 0px;
}

.nav-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto; /* Pushes nav-item to the right */
    font-size: 16px; /* Unified font size */
    white-space: nowrap; /* Prevents wrapping */
}

.username {
    margin-left: 0px;
    /* width: 300px; */
    text-align: right; /* Align text to the right */
    white-space: nowrap; /* Prevents wrapping */
    font-size: 16px; /* Same font size as logout */
}

.company-header{
    margin-left: 0px;
    margin-right: 10px;
    /* width: 300px; */
    text-align: right; 
    white-space: nowrap; 
    font-size: 16px; 
    /* background-color: red; */
}

.logout {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    margin-left: 10px;
    font-size: 16px; /* Same font size as username */
    text-decoration: none;
    padding: 5px 10px; /* Smaller button */
    width: 100px;
}

.logout:hover {
    background-color: rgb(216, 216, 216);
}
