/* Home.css */
.home-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-layout {
  display: flex;
  flex-grow: 1;
}


.left-panel {
  margin-right: 20px;
}

.content {
  flex-grow: 1;
}

/* Ensure no additional margin or padding is present */
/* Reset any default margins or padding */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
/* This ensures padding does not affect the total width */