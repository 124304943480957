.form-textlabel-no-bottom {
    font-size: 20px;
    margin-bottom: 0px;
    white-space: nowrap;
}

.form-textlabel {
    font-size: 20px;
    margin-bottom: 15px;
    // background-color: red;
    white-space: nowrap;
}

.form-textlabel-wide {
    // width: 850px;
    width: 100%;
}

.form-textlabel-bigger-bottom {
    font-size: 20px;
    margin-bottom: 40px;
}

.required-asterisk {
    color: red;
    font-size: 22px;
}

.error-message {
    color: red;
    // margin-top: 10px;
    margin-bottom: 10px;
    font-size: 22px;
}


.important-info {
    color: red;
    font-size: 16px;
    margin-left: 40px;
}

.important-info-no-left-margin {
    color: red;
    font-size: 16px;
}
